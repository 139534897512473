import React from "react";

export default function BoardMenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.633 13.1835H16.7085C16.0875 13.1835 15.5835 12.6795 15.5835 12.0585C15.5835 11.4375 16.0875 10.9335 16.7085 10.9335H24.633C25.254 10.9335 25.758 11.4375 25.758 12.0585C25.758 12.6795 25.254 13.1835 24.633 13.1835ZM24.633 19.1235H16.7085C16.0875 19.1235 15.5835 18.6195 15.5835 17.9985C15.5835 17.3775 16.0875 16.8735 16.7085 16.8735H24.633C25.254 16.8735 25.758 17.3775 25.758 17.9985C25.758 18.6195 25.254 19.1235 24.633 19.1235ZM24.633 25.0635H16.7085C16.0875 25.0635 15.5835 24.5595 15.5835 23.9385C15.5835 23.3175 16.0875 22.8135 16.7085 22.8135H24.633C25.254 22.8135 25.758 23.3175 25.758 23.9385C25.758 24.5595 25.254 25.0635 24.633 25.0635ZM11.8245 13.1835H11.367C10.746 13.1835 10.242 12.6795 10.242 12.0585C10.242 11.4375 10.746 10.9335 11.367 10.9335H11.8245C12.4455 10.9335 12.9495 11.4375 12.9495 12.0585C12.9495 12.6795 12.4455 13.1835 11.8245 13.1835ZM11.8245 19.1235H11.367C10.746 19.1235 10.242 18.6195 10.242 17.9985C10.242 17.3775 10.746 16.8735 11.367 16.8735H11.8245C12.4455 16.8735 12.9495 17.3775 12.9495 17.9985C12.9495 18.6195 12.4455 19.1235 11.8245 19.1235ZM11.8245 25.0635H11.367C10.746 25.0635 10.242 24.5595 10.242 23.9385C10.242 23.3175 10.746 22.8135 11.367 22.8135H11.8245C12.4455 22.8135 12.9495 23.3175 12.9495 23.9385C12.9495 24.5595 12.4455 25.0635 11.8245 25.0635ZM24.3255 3.75H11.673C6.9345 3.75 3.75 7.0815 3.75 12.039V23.961C3.75 28.9185 6.9345 32.25 11.673 32.25H24.324C29.064 32.25 32.25 28.9185 32.25 23.961V12.039C32.25 7.0815 29.0655 3.75 24.3255 3.75Z"
        fill="#FF9D00"
      />
    </svg>
  );
}
