import { callApi } from "..";

export const apiGetKitchenList = () =>
  callApi(
    `{
            getKitchenStations {
                id
                restaurant_id
                name
                printer_id
                printer_name
            }
        }`,
    null
  );

export const apiSetKitchenStation = (data) =>
  callApi(
    `mutation(
    $kitchen_stations_ids: [Int]!) {
    setKitchenStationForKitchen(
        kitchen_stations_ids: $kitchen_stations_ids
    )
}`,
    data
  );
