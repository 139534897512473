import React from "react";
import Container from "layouts/Container";
import { apiGetKitchenList, apiSetKitchenStation } from "../../apis/Kitchen";
import { Select, Spin } from "antd";
import icon_back from "../../assets/icons/back.png";
import ic_information from "../../assets/icons/information.png";
import ic_close from "../../assets/closeIconOrange.png";
import "./index.scss";

const { Option } = Select;

const Index = () => {
  const [kitchenStations, setKitchenStations] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(undefined);
  const [kitchenListSelected, setKitchenListSelected] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState(false);

  const saveKitchenStations = async () => {
    if (kitchenListSelected?.length > 0) {
      apiSetKitchenStation({
        kitchen_stations_ids: kitchenListSelected?.map((item) =>
          parseInt(item?.id)
        ),
      });
      await localStorage.setItem(
        "kitchenStations",
        JSON.stringify(kitchenListSelected)
      );
      window.location.href = "/home";
    } else {
      setErrorMessage(true);
    }
  };

  const onCancel = () => {
    window.location.href = "/home";
  };
  const [loading, setLoading] = React.useState(false);
  const getKitchenList = () => {
    try {
      setLoading(true);
      apiGetKitchenList().then((res) => {
        setKitchenStations(res?.data?.getKitchenStations ?? []);
        getKitchenSelected();
        setLoading(false);
      });
    } catch (err) {
      console.log("error get kitchen stations : ", err);
    }
  };

  const handleChange = (value) => {
    if (value == "all") {
      setKitchenListSelected(kitchenStations);
      return;
    }
    const item = kitchenStations.find((obj) => obj?.id == value);
    const itemList = [...kitchenListSelected];
    if (item) {
      const foundItem = itemList.find((obj) => obj?.id == value);
      !foundItem && itemList.push(item);
    }
    setKitchenListSelected(itemList);
    setSelectedValue(value);
    setErrorMessage(false);
  };

  const removeItem = (item) => {
    let itemList = [...kitchenListSelected];
    itemList = itemList.filter((obj) => obj?.id !== item?.id);
    setKitchenListSelected(itemList);
  };

  const getKitchenSelected = async () => {
    let temp = await localStorage.getItem("kitchenStations");
    console.log(temp);
    if (temp) {
      temp = JSON.parse(temp);
      setKitchenListSelected(temp);
    }
  };

  const back = () => {
    window.location.href = "/home";
  };

  React.useEffect(() => {
    getKitchenList();
  }, []);

  return (
    <Container>
      {loading && (
        <div className="loading">
          <Spin />
        </div>
      )}
      <div className="container-wrap">
        <div className="header-wrap">
          <img
            style={{ width: 25, height: 25, cursor: "pointer" }}
            src={icon_back}
            alt="ic_back"
            onClick={back}
          />
          <p className="settings-title">Settings</p>
        </div>
        <div className="kitchen-container">
          <div className="station-display">
            <h4>Station Display</h4>
            <img src={ic_information} alt="ic_information" />
          </div>
          <p className="kitchen-station-label">Kitchen Station</p>
          <Select
            value={selectedValue}
            style={{ width: 300, marginTop: 8 }}
            onChange={handleChange}
            placeholder="Please select"
            className="selectKitchenSetting"
          >
            <Option key={"all"} value={"all"}>
              All
            </Option>
            {kitchenStations.map((obj) => (
              <Option key={obj?.id + Math.random()} value={obj?.id}>
                {obj?.name}
              </Option>
            ))}
          </Select>
          <div style={{ display: "flex", marginTop: 16, flexWrap: "wrap" }}>
            {kitchenListSelected.map((item) => (
              <div className="itemSelected" key={item?.id}>
                {item?.name}
                <img
                  src={ic_close}
                  onClick={() => removeItem(item)}
                  className="imgSelected"
                />
              </div>
            ))}
          </div>

          {errorMessage && (
            <div style={{ color: "red", fontSize: 16 }}>
              Please select kitchen station
            </div>
          )}

          <div className="button-container">
            <div onClick={onCancel} className="button-kitchen">
              Cancel
            </div>
            <div onClick={saveKitchenStations} className="button-kitchen save">
              Save
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Index;
